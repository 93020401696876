<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept type="search" label="LBLDEPT" name="deptCd" v-model="searchParam.deptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            label="상담기간"
            name="period"
            defaultStart="-6M"
            defaultEnd="6M"
            v-model="period"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="상담 이력"
      tableId="table"
      :columns="grid.columns"
      :merge="grid.merge"
      :data="grid.data"
      @linkClick="linkClick"
      :columnSetting="false"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="detailPopup" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'consult-history',
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'mergeGroup' },
          { index: 1, colName: 'mergeGroup' },
          { index: 2, colName: 'mergeGroup' },
          { index: 3, colName: 'mergeGroup' },
          { index: 4, colName: 'mergeGroup' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            style: 'width: 100px',
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
          },
          {
            name: 'suspectFlagName',
            field: 'suspectFlagName',
            style: 'width: 100px',
            label: '상담자 구분',
            align: 'center',
            sortable: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            style: 'width: 100px',
            label: 'LBLDEPT',
            align: 'center',
            sortable: true,
          },
          {
            name: 'empNo',
            field: 'empNo',
            label: '사번',
            style: 'width: 100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            style: 'width: 100px',
            label: '대상자',
            align: 'center',
            sortable: true,
          },
          {
            name: 'consultDate',
            field: 'consultDate',
            label: '상담일',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'consultTypeName',
            field: 'consultTypeName',
            label: '상담구분',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'consultContent',
            field: 'consultContent',
            label: '상담내용',
            align: 'left',
            type: 'link',
            style: 'width: 220px',
            sortable: false,
          },
          {
            name: 'heaBrainCardiovascularSurveyName',
            field: 'heaBrainCardiovascularSurveyName',
            label: '관련 뇌심혈관계',
            align: 'left',
            style: 'width: 180px',
            sortable: false,
            type: 'link',
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: '',
        deptCd: '',
        startYmd: '',
        endYmd: '',
      },
      period: [],
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: true,
        top: '',
        param: {},
        closeCallback: null,
      },
      consultUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.consultUrl = selectConfig.hea.checkup.suspect.history.consult.url;
      this.getList();
    },
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.consultUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row, col) {
      if (col.name === 'consultContent') {
        this.detailPopup(row);
      } else {
        this.brainCardiovascularPopup(row);
      }
    },
    detailPopup(result) {
      this.popupOptions.target = () => import(`${"./consultDetail.vue"}`);
      this.popupOptions.title = '건강상담 상세';
      this.popupOptions.isFull = false;
      this.popupOptions.width = '80%';
      this.popupOptions.param = {
        suspectConsultId: result? result.suspectConsultId : '',
        suspectId: result ? result.suspectId : '',
        suspectFlag: result ? result.suspectFlag : 'N',
        suspectUserName: result ? result.deptName + ' / ' +  result.userName : '',
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    brainCardiovascularPopup(row) {
      this.popupOptions.title = '뇌심혈관계';
      this.popupOptions.param = {
        heaBrainCardiovascularSurveyId: row.heaBrainCardiovascularSurveyId,
      };
      this.popupOptions.target = () => import(`${'@/pages/hea/bcs/brainCardiovascularDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
